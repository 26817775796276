var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.state.loaded)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-skeleton-loader',{attrs:{"type":"table"}})],1)],1):_c('v-container',{staticClass:"o-discount-list"},[_c('v-data-table',{attrs:{"items":_vm.state.items,"item-key":"id","headers":_vm.state.headers,"options":_vm.state.options,"server-items-length":_vm.state.total,"loading":_vm.state.loading,"footer-props":{ itemsPerPageOptions: [20, 50, 100, 200, 500, 1000, 2000, 5000] }},on:{"update:options":function($event){return _vm.$set(_vm.state, "options", $event)}},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getDiscountType(item.type)))])]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [(item.type === 'percent')?_c('span',[_vm._v(_vm._s(parseFloat(item.value))+" %")]):_c('span',[_vm._v(_vm._s(parseFloat(item.value))+" "+_vm._s(_vm.state.currency))])]}},{key:"item.codes",fn:function(ref){
var item = ref.item;
return [(item.codeType === 'single use')?_c('span',[_vm._v(_vm._s(_vm.$t('event.panel.config.discounts.add.singleUseCode')))]):_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v(_vm._s(_vm.$t('event.panel.config.discounts.add.multipleUseCode')))]),_c('span',[_vm._v(_vm._s(_vm.$t('layout.misc.remainingAmount'))),(item.amount)?_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(item.amount))]):_c('span',{staticClass:"ml-2"},[_vm._v("---")])])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close")])]}},{key:"item.relations",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[(item.isGlobal)?[_c('div',{staticClass:"mb-1"},[_c('span',[_c('strong',[_vm._v(_vm._s(_vm.$t('layout.misc.globalCode')))])])]),_c('div',{staticClass:"mb-1"},[_c('span',{staticClass:"mr-1"},[_c('strong',[_vm._v(_vm._s(_vm.$t('layout.misc.assignedTo'))+":")])]),(item.assignedToModule === 'additionalService')?_c('span',[_vm._v(_vm._s(_vm.$t('layout.misc.additionalServicesModule')))]):_vm._e()])]:[(item.registrationFees && item.registrationFees.length)?_c('div',{staticClass:"mb-1"},[_c('span',{staticClass:"mr-2"},[_c('strong',[_vm._v(_vm._s(_vm.$t('layout.misc.registrationFees'))+":")])]),_vm._l((item.registrationFees),function(fee){return _c('v-chip',{key:fee.id,staticClass:"mr-1",attrs:{"small":""}},[_vm._v(_vm._s(fee.name))])})],2):_vm._e(),(item.additionalServices && item.additionalServices.length)?_c('div',{staticClass:"mb-1"},[_c('span',{staticClass:"mr-2"},[_c('strong',[_vm._v(_vm._s(_vm.$t('layout.misc.services'))+":")])]),_vm._l((item.additionalServices),function(service){return _c('v-chip',{key:service.id,staticClass:"mr-1",attrs:{"small":""}},[_vm._v(_vm._s(service.name))])})],2):_vm._e(),(item.rooms && item.rooms.length)?_c('div',{staticClass:"mb-1"},[_c('span',{staticClass:"mr-2"},[_c('strong',[_vm._v(_vm._s(_vm.$t('layout.misc.accommodation'))+":")])]),_vm._l((item.rooms),function(room){return _c('v-chip',{key:room.id,staticClass:"mr-1",attrs:{"small":""}},[_vm._v(_vm._s(room.name))])})],2):_vm._e()]],2)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","x-small":"","fab":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.changeDiscountStatus(item)}}},[(!item.status)?_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-check")])],1):_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('layout.misc.status')))])],1),(item.codeType === 'single use' && item.status)?_c('v-list-item',{on:{"click":function($event){$event.preventDefault();return _vm.copyVariable(item.id)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-content-copy")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('event.panel.config.discounts.list.copyVariable')))])],1):_vm._e(),(item.codeType === 'multiple use' && item.status)?_c('v-list-item',{on:{"click":function($event){$event.preventDefault();return _vm.copyMultipleCode(item.codes[0].value)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-content-copy")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('event.panel.config.discounts.list.copyMultipleCode')))])],1):_vm._e(),_c('v-list-item',{attrs:{"to":{ name: 'panel.event.view.discount.edit', params: { did: item.id } }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('layout.misc.edit')))])],1),_c('v-dialog',{attrs:{"max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('layout.misc.delete')))])],1)]}}],null,true),model:{value:(item.deleteDialog),callback:function ($$v) {_vm.$set(item, "deleteDialog", $$v)},expression:"item.deleteDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('layout.misc.areYouSure')))]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){item.deleteDialog = false}}},[_vm._v(_vm._s(_vm.$t('layout.misc.cancel')))]),_c('v-btn',{attrs:{"depressed":"","dark":"","color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(_vm._s(_vm.$t('layout.misc.delete')))])],1)],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }