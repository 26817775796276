




























































































import useMisc from "@/use/misc";
import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { DataOptions } from "vuetify";
import { v4 as uuid } from "uuid";
import { ChangeDiscountStatusData } from "@/types/discount";

export default defineComponent({
  setup(_, { root }) {
    const state = reactive({
      headers: [
        { value: "name", text: root.$tc("layout.misc.name") },
        { value: "type", text: root.$tc("layout.misc.type") },
        { value: "value", text: root.$tc("layout.misc.value") },
        {
          value: "codes",
          text: root.$tc("event.panel.config.discounts.add.discountCodes"),
        },
        {
          value: "status",
          text: root.$tc("layout.misc.state"),
          width: 1,
          align: "right",
        },
        {
          value: "relations",
          text: root.$tc("layout.misc.connectedElements"),
        },
        {
          value: "actions",
          text: "",
          sortable: false,
          align: "right",
          width: "120px",
        },
      ],
      empty: false,
      items: [],
      table: false,
      loading: false,
      loaded: false,
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 50,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      currency: "",
    });

    const { getDiscountType } = useMisc({ root });

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      const {
        sortBy,
        sortDesc,
        page,
        itemsPerPage,
      } = state.options as DataOptions;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/discount`, {
          params: { sortBy, sortDesc, page, itemsPerPage },
        })
        .then(({ data: { discounts, total } }) => {
          state.empty = false;
          state.items = discounts;
          state.total = total;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.empty = true;
            state.items = [];
            state.total = 0;
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchData);

    // Fetch event

    const fetchEvent = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}`)
        .then(({ data: { event } }) => {
          state.currency = event.currency;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchEvent);

    const deleteItem = (item: { id: string; deleteDialog: boolean }) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .delete(`/discount/${item.id}`, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          item.deleteDialog = false;
          fetchData();
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    watch(() => state.options, fetchData, { deep: true });

    const copyVariable = (id: string) => {
      navigator.clipboard.writeText(`{{{ discount:${id} }}}`);
      root.$store.commit("snackbar/PUSH_MESSAGE", {
        id: uuid(),
        color: "primary",
        message: root.$tc("event.panel.config.discounts.list.variableCopied"),
      });
    };

    const copyMultipleCode = (value: string) => {
      navigator.clipboard.writeText(value);
      root.$store.commit("snackbar/PUSH_MESSAGE", {
        id: uuid(),
        color: "primary",
        message: root.$tc("event.panel.config.discounts.list.codeCopied"),
      });
    };

    const changeDiscountStatus = (item: ChangeDiscountStatusData) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      state.loading = true;

      const data = {
        name: item.name,
        type: item.type,
        value: item.value,
        startDate: item.startDate || undefined,
        endDate: item.endDate || undefined,
        amount: item.amount,
        status: !item.status,
        codes: item.codes.map(
          (el: { id: string; value: string; used: boolean }) => ({
            id: el.id,
            value: el.value,
            used: el.used,
          })
        ),
      };

      axiosInstance
        .put(`discount/${item.id}`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          fetchData();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          state.loading = false;
        });
    };

    return {
      state,
      fetchData,
      deleteItem,
      getDiscountType,
      copyVariable,
      copyMultipleCode,
      changeDiscountStatus,
    };
  },
});
